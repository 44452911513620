@import '../scss/colors.scss';
@import '../scss/font.scss';
@import '../scss/animations.scss';

#home {
    position: relative;
    background-color: $cinza-escuro;
    overflow-x: hidden;

    .home__menu {
        position: absolute;
        width: 20%;
        left: 40%;
        top: 35%;
        z-index: 1;
    
    
        .home__menu__logo {
            display: block;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    
    }
}


.area {
    position: relative;
    width: 100%;
    height: 50vh;
    background-size: cover;

    .area__link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-size: 30px;
        text-align: center;
        color: $bege;
        width: 100%;
        height: 50vh;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 800;
        animation: blur-out-expand 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

        &:hover {
            background-color: rgba($color: $bege, $alpha: 0.5);
            color: $preto;
            -webkit-transition: all 0.4s ease-in;
            transition: all 0.4 ease-in;
            -webkit-animation: focus-in-contract 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focus-in-contract 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        }
    }
}




@media only screen and (max-width:767px) {




    #home {
        height: 100vh;
        overflow: hidden;

        .home__menu {
            top: 15%;
            left: 10%;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
    
    
            .home__menu__logo {
                display: block;
                width: 75%;
                margin-top: 15vw;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 10vw;
            }
    
            .home__menu__link {
                display: block;
                font-size: 22px;
                color: $bege;
                font-weight: 800;
                margin-bottom: 8vw;
                animation: focus-in-contract 2.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                text-decoration: none;
                text-align: center;
                text-transform: uppercase;
            }
        
        }

    }

    .area {
        position: relative;
        width: 100%;
        height: 25vh;
        background-size: cover;
    
        .area__link {
            display: none;
        }
    }
}


@media only screen and (min-width: 900px) and (max-width: 1300px) {

    #home {
        .menu {
            width: 30%;
            left: 35%;
        }
    }

    .area {
        .area__link {
            font-size: 20px;
        }
    }

}

@media only screen and (min-width: 1301px) and (max-width: 1500px) {

    #home {
        .menu {
            width: 24%;
            left: 38%;
            top: 33%;
        }
    }

    .area {
        .area__link {
            font-size: 24px;
        }
    }

}

@media only screen and (min-width: 1501px) and (max-width: 1700px) {

    .area {
        .area__link {
            font-size: 24px;
        }
    }

}