@font-face {
    font-family: "flaticon_reduto";
    src: url("./flaticon_reduto.ttf?2d871e5ff99ed1f78dbb32b32f441e2f") format("truetype"),
url("./flaticon_reduto.woff?2d871e5ff99ed1f78dbb32b32f441e2f") format("woff"),
url("./flaticon_reduto.woff2?2d871e5ff99ed1f78dbb32b32f441e2f") format("woff2"),
url("./flaticon_reduto.eot?2d871e5ff99ed1f78dbb32b32f441e2f#iefix") format("embedded-opentype"),
url("./flaticon_reduto.svg?2d871e5ff99ed1f78dbb32b32f441e2f#flaticon_reduto") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_reduto !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-facebook-app-symbol:before {
    content: "\f101";
}
.flaticon-youtube:before {
    content: "\f102";
}
.flaticon-social-media:before {
    content: "\f103";
}
.flaticon-vimeo:before {
    content: "\f104";
}
.flaticon-menu-hand-drawn-lines-outlines:before {
    content: "\f105";
}
.flaticon-cancel-hand-drawn-cross-in-square-button-outline:before {
    content: "\f106";
}
.flaticon-twitter:before {
    content: "\f107";
}
.flaticon-black-triangular-arrowhead-pointing-to-left-direction:before {
    content: "\f108";
}
.flaticon-mail:before {
    content: "\f109";
}
