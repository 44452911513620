@import '../scss/colors.scss';
@import '../scss/font.scss';

#menu {

    position: absolute;
    width: 100%;
    top: 2%;
    padding-left: 5vw;
    padding-right: 5vw;
    z-index: 99;

    .menu__logo {
        width: 50%;
    }

    .menu__option {
        display: block;
        margin-top: 1vw;
        font-size: 16px;
        color: $bege;
        text-transform: uppercase;
        text-align: center;
        font-weight: 600;

        &:hover {
            color: $cinza-claro;
        }
    }

    .menu__link {
        display: inline-block;
        text-align: right;
        text-decoration: none;
        margin-right: 1vw;

    }

    .menu__icon {
        display: block;
        color: $bege;
        font-size: 20px;
        position: relative;
        z-index: 1;
        margin-top: 1vw;

        &:hover {
                color: $cinza-claro;
        }

    }

    .menu__language {
        text-align: right;
        margin-top: 0.75vw;

        .menu__language__link {
            font-size: 14px;
            color: $cinza-claro;
            font-weight: 700;
            text-decoration: none;

            &:hover {
                color: $bege;
            }
        }

        .menu__language__text {
            font-size: 20px;
            color: $bege;
            font-weight: 700;
            padding-left: 1vw;
            padding-right: 1vw;
        }

        .active {
            color: $bege;
        }
    }
    
}


@media only screen and (max-width:767px) {

    #menu {
        top: 2.5%;
        padding-left: 7.5vw;
        padding-right: 7.5vw;

        .menu__logo {
            display: none;
        }

        .menu__link {
            margin-right: 3.5vw;
        }
    
    }

}

@media only screen and (min-width: 1100px) and (max-width: 1300px) {

    #menu {
        .menu__option {
            font-size: 12px;
        }

        .menu__icon {
            font-size: 12px;
        }

        .menu__language {

            .menu__language__link {
                font-size: 8px;
            }
        }
    }
}


@media only screen and (min-width: 1100px) and (max-width: 1300px) {

    #menu {
        .menu__option {
            font-size: 14px;
        }

        .menu__icon {
            font-size: 14px;
        }

        .menu__language {

            .menu__language__link {
                font-size: 10px;
            }
        }
    }
}


@media only screen and (min-width: 1300px) and (max-width: 1400px) {

    #menu {
        .menu__language {

            .menu__language__link {
                font-size: 10px;
            }
        }
    }
}