
.scale-in-ver-center {
	animation: scale-in-ver-center 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.scale-down-center {
	animation: scale-down-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes scale-in-ver-center {
    0% {
      transform: scaleY(0);
      opacity: 1;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }
  

  /* ----------------------------------------------
 * Generated by Animista on 2023-7-17 15:20:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-down-center
 * ----------------------------------------
 */
@keyframes scale-down-center {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.5);
    }
  }
  

  /* ----------------------------------------------
 * Generated by Animista on 2023-7-20 17:22:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation focus-in-contract
 * ----------------------------------------
 */
@-webkit-keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-20 18:26:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation blur-out-expand
 * ----------------------------------------
 */
 @keyframes blur-out-expand {
  0% {
    filter: blur(0.01);
  }
  100% {
    letter-spacing: 1em;
    filter: blur(12px) opacity(0%);
  }
}
