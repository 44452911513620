@import '../scss/colors.scss';
@import '../scss/font.scss';
@import '../scss/animations.scss';

#projetos {
    position: relative;
    overflow-x: hidden;
    height: 100vh;
    background-color: $preto;
}

a {
    text-decoration: none !important;
}


.projetos {
    padding-top: 6vw;
    padding-left: 2vw;
    padding-right: 2vw;

    .projetos__projeto {
        position: relative;
        margin-bottom: 4vw;

        .projetos__projeto__title {
            font-size: 30px;
            color: $bege;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 0;
            text-align: right;
        }

        .projetos__projeto__img {
            position: relative;
            width: 100%;
        }

        .projetos__projeto__info {
            position: absolute;
            top: 14%;
            right: 1%;
            padding-bottom: 0;

            .projetos__projeto__info__title {
                font-size: 24px;
                color: $bege;
                font-weight: 700;
                margin-bottom: 1vw;
            }

            .projetos_projeto__info__icon {
                display: inline-block;
                font-size: 16px;
                margin-right: 0.5vw;
            }
        }
    }
}

@media only screen and (max-width:767px) {

    .projetos {
        padding-top: 40vw;
        padding-bottom: 10vw;

        .projetos__projeto {
            margin-bottom: 20vw;

            .projetos__projeto__title {
                font-size: 24px;
                margin-bottom: 0;
            }

            .projetos__projeto__info {
                top: 20%;

                .projetos__projeto__info__title {
                    font-size: 18px;
                }
            }
        }
    }

}


@media only screen and (min-width:768px) and (max-width:1399px) {

    .projetos {
        .projetos__projeto {
            .projetos__projeto__title {
                font-size: 20px;
                margin-bottom: 0px;
            }
    
            .projetos__projeto__info {
                .projetos__projeto__info__title {
                    font-size: 16px;
                }
    
                .projetos_projeto__info__icon {
                    font-size: 14px;
                }
            }
        }
    }

}

@media only screen and (min-width:1400px) and (max-width:1700px) {

    .projetos {
        .projetos__projeto {
            .projetos__projeto__title {
                font-size: 30px;
                margin-bottom: 0px;
            }
    
            .projetos__projeto__info {
                .projetos__projeto__info__title {
                    font-size: 16px;
                }
    
                .projetos_projeto__info__icon {
                    font-size: 14px;
                }
            }
        }
    }

}