@import '../scss/colors.scss';
@import '../scss/font.scss';
@import '../scss/animations.scss';

#filmes {
    position: relative;
    overflow-x: hidden;
    height: 100vh;
    background-color: $preto;
}

a {
    text-decoration: none !important;
}


.filmes {
    padding-top: 6vw;
    padding-left: 2vw;
    padding-right: 2vw;

    .filmes__filme {
        position: relative;
        margin-bottom: 4vw;

        .filmes__filme__title {
            font-size: 45px;
            color: $bege;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 0;
            text-align: right;
        }

        .filmes__filme__video {
            position: relative;
            width: 100%;
        }

        .filmes__filme__info {
            position: absolute;
            top: 14%;
            right: 1%;
            padding-bottom: 0;

            .filmes__filme__info__title {
                font-size: 24px;
                color: $bege;
                font-weight: 700;
                margin-bottom: 1vw;
            }

            .filmes_filme__info__icon {
                display: inline-block;
                font-size: 16px;
                margin-right: 0.5vw;
            }
        }
    }
}

@media only screen and (max-width:767px) {

    .filmes {
        padding-top: 40vw;
        padding-bottom: 10vw;

        .filmes__filme {
            margin-bottom: 20vw;

            .filmes__filme__title {
                font-size: 24px;
                margin-bottom: 0;
            }

            .filmes__filme__info {
                top: 20%;

                .filmes__filme__info__title {
                    font-size: 18px;
                }
            }
        }
    }

}


@media only screen and (min-width:768px) and (max-width:1399px) {

    .filmes {
        .filmes__filme {
            .filmes__filme__title {
                font-size: 20px;
                margin-bottom: 0px;
            }
    
            .filmes__filme__info {
                .filmes__filme__info__title {
                    font-size: 16px;
                }
    
                .filmes_filme__info__icon {
                    font-size: 14px;
                }
            }
        }
    }

}

@media only screen and (min-width:1400px) and (max-width:1700px) {

    .filmes {
        .filmes__filme {
            .filmes__filme__title {
                font-size: 36px;
                margin-bottom: 0px;
            }
    
            .filmes__filme__info {
                .filmes__filme__info__title {
                    font-size: 16px;
                }
    
                .filmes_filme__info__icon {
                    font-size: 14px;
                }
            }
        }
    }

}