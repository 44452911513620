@import '../scss/colors.scss';
@import '../scss/font.scss';
@import '../scss/animations.scss';

.menuMobile {
    position: absolute;
    top: 0%;
    width: 10.5%;
    left: 5%;
    display: none;

    .menuMobile__header {
        position: relative;
        z-index: 99;
        
        .menuMobile__header__logo {
            display: inline-block;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            user-select: none;
    
        }
    
        .menuMobile__header__icon {
            display: none;
            position: absolute;
            top: 50%;
            color: $bege;
            font-size: 26px;
            cursor: pointer;
            z-index: 99;
        }
    
        .menuMobile__header__icon--close {
            position: absolute;
            top: 50%;
            color: $bege;
            font-size: 24px;
            cursor: pointer;
            z-index: 99;
            right: -4%;
        }
    }

    .menuMobile__content {
        position: relative;
        padding: 1vw;
        background-color: $preto;
        z-index: 99;
        border: 3px solid $bege;

        .menuMobile__content__link {
            display: block;
            position: relative;
            font-size: 14px;
            color: $bege;
            font-weight: 800;
            padding-bottom: 2vw;
            text-decoration: none;
            text-align: center;
            text-transform: uppercase;
            user-select: none;
            z-index: 99;
            
            &:hover {
                color: $azul-escuro;
            }

            &:last-of-type {
                padding-bottom: 0;
            }

            &:first-of-type {
                padding-top: 0.5vw;
            }
        }
    }
}

@media only screen and (max-width:767px) {

    .menuMobile {
        display: block;
        top: 15%;
        width: 40%;
        left: 30%;

        .menuMobile__header {
            .menuMobile__header__logo {
                display: block;
            }

            .menuMobile__header__icon {
                display: block;
                font-size: 24px;
                right: -4%;
            }
        }

        .menuMobile__link {
            font-size: 16px;
            margin-bottom: 4vw;

            &:hover {
                color: $bege;
            }
        }

        .menuMobile__content {
            padding: 4vw;
            width: 50vw;

            .menuMobile__content__link {
                margin-bottom: 4vw;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

        }
    }

}

@media only screen and (min-width:768px) and (max-width:1399px) {

    .menuMobile {

        .menuMobile__content {
            .menuMobile__content__link {
                font-size: 10px;
            }
        }
    }

}

@media only screen and (min-width:1400px) and (max-width:1700px) {

    .menuMobile {

        .menuMobile__content {
            .menuMobile__content__link {
                font-size: 11px;
            }
        }
    }

}