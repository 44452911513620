@import '../scss/colors.scss';
@import '../scss/font.scss';

.socialhome {
    position: absolute;
    width: 100%;
    top: 2%;
    padding-left: 5vw;
    padding-right: 5vw;
    z-index: 99;

    .socialhome__link {
        display: inline-block;
        text-align: center;
        text-decoration: none;
        margin-right: 1vw;
    }

    .socialhome__icon {
        display: block;
        color: $bege;
        font-size: 20px;
        position: relative;
        z-index: 1;

        &:hover {
             color: $cinza-claro;
        }

    }

    .socialhome__language {
        text-align: right;

        .socialhome__language__link {
            font-size: 14px;
            color: $cinza-claro;
            font-weight: 700;
            text-decoration: none;

            &:hover {
                color: $bege;
            }
        }

        .socialhome__language__text {
            font-size: 20px;
            color: $bege;
            font-weight: 700;
            padding-left: 1vw;
            padding-right: 1vw;
        }

        .active {
            color: $bege;
        }
    }
}


@media only screen and (max-width:767px) {

    .socialhome {
        top: 3%;
        .socialhome__link {
            margin-right: 3.5vw;
        }
    
    }
}