@import '../scss/colors.scss';
@import '../scss/font.scss';
@import '../scss/animations.scss';

#filme {
    position: relative;
    overflow-x: hidden;
    height: 100vh;
    background-color: $preto;
    background-size: cover;
}

.filme {
    padding-left: 2vw;
    padding-right: 2vw;

    .filme__info {
        margin-top: 15vw;
        padding-left: 4vw;
        padding-right: 4vw;
        border-right: 2px solid $bege;
        margin-bottom: 8vw;
        position: sticky;
        top: 4vw;

        .filme__info__title {
            font-size: 44px;
            color: $bege;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 2vw;
        }

        .filme__info__subtitle {
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 700;
            color: $cinza-claro;
            line-height: 2;
            margin-bottom: 0;
        }

        .filme__info__text {
            font-size: 18px;
            color: $bege;
            font-weight: 400;
            margin-bottom: 2vw;
            line-height: 2;
        }

    }

    .filme__content{
        margin-top: 15vw;
        margin-bottom: 8vw;

        .filme__content__trailer {
            .filme__content__trailer__title {
                font-size: 30px;
                color: $cinza-claro;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 2vw;
            }

            .filme__content__trailer__frame {
                width: 32vw;
                height: 18vw;
                margin-bottom: 4vw;
            }

        }

        .filme__content__ficha {
            margin-bottom: 4vw;

            .filme__content__ficha__title {
                font-size: 30px;
                color: $cinza-claro;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 2vw;
            }

            .filme__content__ficha__list {
                padding-left: 0;
                list-style: none;
                width: 40%;

                .filme__content__ficha__list__option {
                    font-size: 18px;
                    color: $bege;
                    font-weight: 400;
                    margin-bottom: 1vw;
                    line-height: 2;
                }
            }
        }

        .filme__content__festivais {
            margin-bottom: 4vw;

            .filme__content__festivais__title {
                font-size: 30px;
                color: $cinza-claro;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 2vw;
            }

            .filme__content__festivais__list {
                padding-left: 0;
                list-style: none;
                width: 40%;

                .filme__content__festivais__list__option {
                    font-size: 18px;
                    color: $bege;
                    font-weight: 400;
                    margin-bottom: 1vw;
                    line-height: 2;
                }
            }
        }

        .filme__content__galeria {
            width: 80%;
            margin-bottom: 8vw;

            .filme__content__galeria__title {
                font-size: 30px;
                color: $cinza-claro;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 2vw;
            }

            .filme__content__galeria__img {
                width: 100%;
                cursor: pointer;
                margin-bottom: 1vw;
            }

            .filme__content__galeria__col {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

a {
    text-decoration: none !important;
}

.azul {
    color: $cinza-claro;
    font-weight: 700;
}

.modal {

    .modal__img {
        width: 100%;
    }

    .modal__close {
        display: block;
        margin-top: 1vw;
        margin-left: auto;
        margin-right: auto;
        color: $bege;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        background: none;
        border: none;

        &:hover {
            color: $cinza-claro;
        }
    }

    .modal__option {
        display: block;
        margin-left: auto;
        margin-right: auto;
        color: $cinza-claro;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        background: none;
        border: none;
        margin-top: 1vw;
        cursor: pointer;

        &:hover {
            color: $cinza-claro;
        }

        .modal__right {
            display: block;
            transform: scaleX(-1);
        }
    }

}

.modal-content {
    margin-top: 10vw;
    background-color:none !important;
    background: none !important;
    border: none;

}

.modal.show .modal-dialog {
    min-width: 44vw;
}

.modal-backdrop.show {
    opacity: 0.9 !important;
}


@media only screen and (max-width:767px) {

    .filme {
        padding-top: 60vw;
        background-position: 50%;

        .filme__info {
            padding-left: 10vw;
            padding-right: 10vw;
            border-right: none;

            .filme__info__title {
                font-size: 40px;
                margin-bottom: 10vw;
                line-height: 1.5;
                text-align: center;
            }

            .filme__info__text {
                font-size: 16px;
                margin-bottom: 6vw;
            }
        }

        .filme__content {
            padding-left: 10vw;
            padding-right: 10vw;


            .filme__content__trailer {
                .filme__content__trailer__title {
                    margin-bottom: 10vw;
                }
    
                .filme__content__trailer__frame {
                    width: 64vw;
                    height: 36vw;
                    margin-bottom: 20vw;
                }
    
            }

            .filme__content__ficha {
                margin-bottom: 30vw;
    
                .filme__content__ficha__title {
                    margin-bottom: 10vw;
                }
    
                .filme__content__ficha__list {
                    width: 100%;
    
                    .filme__content__ficha__list__option {
                        font-size: 16px;
                        margin-bottom: 6vw;
                    }
                }
            }

            .filme__content__festivais {
                margin-bottom: 20vw;
    
                .filme__content__festivais__title {
                    margin-bottom: 10vw;
                }
    
                .filme__content__festivais__list {
                    width: 100%;
    
                    .filme__content__festivais__list__option {
                        font-size: 16px;
                        margin-bottom: 6vw;
                    }
                }
            }

            .filme__content__galeria {
                width: 100%;
                margin-bottom: 30vw;
    
                .filme__content__galeria__title {
                    margin-bottom: 10vw;
                }
    
                .filme__content__galeria__img {
                    margin-bottom: 8vw;
                }
    
            }
        }

    }

    .modal {

        .modal__img {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    
        .modal__close {
            margin-top: 6vw;
            font-size: 16px;
    
            &:hover {
                color: $cinza-claro;
            }
        }

        .modal__option {
            margin-top: 6vw;
            font-size: 16px;
    
            &:hover {
                color: $cinza-claro;
            }
        }


    }
    
    .modal-content {
        margin-top: 50vw;
    
    }
    

}


@media only screen and (min-width:768px) and (max-width:1399px) {

    .filme {
        .filme__info {
            .filme__info__title {
                font-size: 30px;
            }

            .filme__info__subtitle {
                font-size: 20px;
            }

            .filme__info__text {
                font-size: 14px;
            }
        }

        .filme__content {

            .filme__content__trailer {
                .filme__content__trailer__title {
                    font-size: 26px;
                }
            }

            .filme__content__ficha {
                .filme__content__ficha__title {
                    font-size: 26px;
                }

                .filme__content__ficha__list {
                    .filme__content__ficha__list__option {
                        font-size: 14px;
                    }
                }
            }

            .filme__content__festivais {
                .filme__content__festivais__title {
                    font-size: 26px;

                }

                .filme__content__festivais__list {
                    .filme__content__festivais__list__option {
                        font-size: 14px;
                    }
                }
            }

            .filme__content__galeria {
                .filme__content__galeria__title {
                    font-size: 26px;
                }
            }

        }
    }


}

@media only screen and (min-width:1400px) and (max-width:1700px) {



}